import React, { useContext, useEffect, useState } from "react";
import Header from "../components/common/Header";
import { FaChevronDown } from "react-icons/fa";
import TokensModal from "../components/common/TokensModal";
import { FaLongArrowAltDown } from "react-icons/fa";
import { appContext } from "../AppContext";
import { formatAddress, formatReserve, formatBalance } from "../functions/commonFunctions";
import { getBalanceAndSymbol, getReserves, getAmountOut, giveAllowance, swapTokens } from "../functions/etherFunctions";
import RemoveLiquidityButton from "../components/RemoveLiquidityButton";
import { removeLiquidity, quoteRemoveLiquidity } from "../functions/LiquidityFunctions";

const RemoveLiquidity = () => {
  const { networkInfo, connectWallet } = useContext(appContext);
  const [tokenModal1, setTokenModal1] = useState(false);
  const [tokenModal2, setTokenModal2] = useState(false);
  const [token1, setToken1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    allowance: undefined,
  });
  const [token2, setToken2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    allowance: undefined,
  });

  const [reserves, setReserves] = useState(["0.0", "0.0"]);

  const [liquidityTokens, setLiquidityTokens] = React.useState("");

  // Stores the input and output for the liquidity removal preview
  const [tokensOut, setTokensOut] = React.useState([0, 0, 0]);

  const [loading, setLoading] = useState(false);

  const [field1Value, setField1Value] = useState("");
  const [field2Value, setField2Value] = useState("");

  const switchFields = () => {
    setToken1(token2);
    setToken2(token1);
    setReserves(reserves.reverse());
  };

  const setTokenOneData = async (item) => {
    try {
      if (item.address === token2.address) {
        switchFields();
      }
      if (item?.address) {
        const data = await getBalanceAndSymbol(
          networkInfo?.address,
          item?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.weth?.address,
          networkInfo.coins,
          networkInfo.router
        );
        setToken1({
          address: item?.address,
          symbol: data.symbol,
          balance: data.balance,
          allowance: data.allowance,
        });
      }
    } catch (err) {
      console.log("error in setTokenOneData : ", err);
    }
  };
  const setTokenTwoData = async (item) => {
    try {
      if (item.address === token1.address) {
        switchFields();
      }
      if (item?.address) {
        const data = await getBalanceAndSymbol(
          networkInfo?.address,
          item?.address,
          networkInfo?.provider,
          networkInfo?.signer,
          networkInfo?.weth?.address,
          networkInfo.coins,
          networkInfo.router
        );
        setToken2({
          address: item?.address,
          symbol: data.symbol,
          balance: data.balance,
          allowance: data.allowance,
        });
      }
    } catch (err) {
      console.log("error in setTokenOneData : ", err);
    }
  };

  useEffect(() => {
    // console.log("Trying to get Reserves between:\n" + token1.address + "\n" + token2.address);

    if (token1.address && token2.address) {
      getReserves(token1.address, token2.address, networkInfo.factory, networkInfo.signer, networkInfo.address).then(
        (data) => {
          setReserves(data);
          setLiquidityTokens(data[2]);
        }
      );
    }
  }, [
    token1.address,
    token2.address,
    networkInfo.address,
    networkInfo.factory,
    networkInfo.router,
    networkInfo.signer,
  ]);

  // Determines whether the button should be enabled or not
  const isButtonEnabled = () => {
    // If both coins have been selected, and a valid float has been entered which is less than the user's balance, then return true
    const parsedInput = parseFloat(field1Value);
    return (
      token1.address &&
      token2.address &&
      parsedInput !== NaN &&
      0 < parsedInput &&
      parsedInput <= liquidityTokens &&
      token1?.allowance > field1Value &&
      token2?.allowance > field2Value
    );
  };

  const isValid = () => {
    // If both coins have been selected, and a valid float has been entered which is less than the user's balance, then return true
    const parsedInput = parseFloat(field1Value);
    return token1.address && token2.address && parsedInput !== NaN && 0 < parsedInput && parsedInput <= liquidityTokens;
  };

  useEffect(() => {
    if (isValid()) {
      // console.log("Trying to preview the liquidity removal");
      quoteRemoveLiquidity(token1.address, token2.address, field1Value, networkInfo.factory, networkInfo.signer).then(
        (data) => {
          setTokensOut(data);
        }
      );
    }
  }, [token1.address, token2.address, field1Value, networkInfo.factory, networkInfo.signer]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      // console.log("networkInfo: ", networkInfo);
      // console.log("Checking balances...");

      if (token1.address && token2.address && networkInfo.address) {
        getReserves(token1.address, token2.address, networkInfo.factory, networkInfo.signer, networkInfo.address).then(
          (data) => setReserves(data)
        );
      }

      if (token1.address && networkInfo.address) {
        getBalanceAndSymbol(
          networkInfo.address,
          token1.address,
          networkInfo.provider,
          networkInfo.signer,
          networkInfo.weth.address,
          networkInfo.coins,
          networkInfo.router
        ).then((data) => {
          setToken1({
            ...token1,
            balance: data.balance,
          });
        });
      }
      if (token2.address && networkInfo.address) {
        getBalanceAndSymbol(
          networkInfo.address,
          token2.address,
          networkInfo.provider,
          networkInfo.signer,
          networkInfo.weth.address,
          networkInfo.coins,
          networkInfo.router
        ).then((data) => {
          setToken2({
            ...token2,
            balance: data.balance,
          });
        });
      }
    }, 10000);

    return () => clearTimeout(coinTimeout);
  });

  useEffect(() => {
    // console.log("Trying to get reserves between:\n" + token1.address + "\n" + token2.address);

    if (token1.address && token2.address && networkInfo.address) {
      getReserves(token1.address, token2.address, networkInfo.factory, networkInfo.signer, networkInfo.address).then(
        (data) => {
          setReserves([data[0], data[1]]);
          setLiquidityTokens(data[2]);
        }
      );
    }
  }, [token1.address, token2.address, networkInfo.address, networkInfo.factory, networkInfo.signer]);

  const giveTokenAllowance1 = async () => {
    if (token1?.address) {
      const result = await giveAllowance(token1?.address, networkInfo.router, networkInfo?.signer);
      if (result) {
        await getBalanceAndSymbol(
          networkInfo.address,
          token2.address,
          networkInfo.provider,
          networkInfo.signer,
          networkInfo.weth.address,
          networkInfo.coins,
          networkInfo.router
        ).then((data) => {
          setToken1({
            ...token1,
            allowance: data.allowance,
          });
        });
      }
    }
  };

  const giveTokenAllowance2 = async () => {
    if (token2?.address) {
      const result = await giveAllowance(
        token2?.address,
        networkInfo.router,
        networkInfo?.signer,
        networkInfo?.provider
      );
      if (result) {
        await getBalanceAndSymbol(
          networkInfo.address,
          token2.address,
          networkInfo.provider,
          networkInfo.signer,
          networkInfo.weth.address,
          networkInfo.coins,
          networkInfo.router
        ).then((data) => {
          setToken2({
            ...token2,
            allowance: data.allowance,
          });
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("token 1 info : ", token1);
  // }, [token1]);
  // useEffect(() => {
  //   console.log("token 2 info : ", token2);
  // }, [token2]);

  const remove = () => {
    setLoading(true);

    removeLiquidity(
      token1.address,
      token2.address,
      field1Value,
      0,
      0,
      networkInfo.router,
      networkInfo.address,
      networkInfo.signer,
      networkInfo.factory,
      networkInfo.provider
    )
      .then(() => {
        setLoading(false);

        // If the transaction was successful, we clear to input to make sure the user doesn't accidental redo the transfer
        setField1Value("");
        // enqueueSnackbar("Removal Successful", { variant: "success" });
      })
      .catch((e) => {
        setLoading(false);
        // enqueueSnackbar("Deployment Failed (" + e.message + ")", {
        //   variant: "error",
        //   autoHideDuration: 10000,
        // });
      });
  };

  return (
    <>
      <div className="w-[600px] border bg-slate-50 min-h-56 rounded-lg p-8 relative border-t-0">
        {" "}
        {!networkInfo.isConnected ? (
          <div className="w-full h-full bg-slate-800/[0.5] absolute top-0 left-0 rounded-lg">
            <div className="flex w-full h-full justify-center items-center">
              <div>
                <div className="w-full flex gap-2 text-white">Please connect to wallet.</div>
                <div className="w-full flex gap-2">
                  <button
                    type="button"
                    className="w-full bg-slate-300 p-3 rounded-lg hover:bg-slate-400 my-2"
                    onClick={() => connectWallet()}
                  >
                    Connect Wallet
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="w-full font-bold text-slate-600">
          <nav className="p-1 flex items-center w-full mb-4 justify-center border bg-slate-50 gap-x-1 rounded-[60px]">
            <a
              type="button"
              className="px-5 py-2 hover:bg-slate-400 hover:text-white w-1/2 text-center rounded-[60px] cursor-pointer flex items-center justify-center"
            >
              Chain
            </a>
            <a
              type="button"
              className="px-5 py-2 hover:bg-slate-400 hover:text-white w-1/2 text-center rounded-[60px] cursor-pointer flex items-center justify-center"
              onClick={connectWallet}
            >
              {networkInfo.isConnected ? (
                <>
                  {formatAddress(networkInfo.address)}
                  <FaChevronDown className="mx-2" />
                </>
              ) : (
                "Connect Wallet"
              )}
            </a>
          </nav>
        </div>
        <div className="w-full text-2xl font-bold text-slate-600">Remove Liquidity</div>
        <div className="w-full bg-slate-300 my-3 rounded-lg p-4">
          <div className="w-full flex justify-between items-center">
            <button
              type="button"
              className="px-5 py-1 rounded-[50px] bg-slate-400 flex items-center"
              onClick={() => setTokenModal1(true)}
            >
              {token1?.symbol ? token1?.symbol : "Select token"}
              <FaChevronDown className="mx-2" />
            </button>
            <div className="w-auto">{formatBalance(token1?.balance, token1?.symbol)}</div>
          </div>
          <div className="">
            <input
              type="text"
              className=" text-right w-full py-0 text-xl bg-transparent outline-none text-slate-600"
              placeholder="0"
              value={field1Value}
              onChange={(e) => setField1Value(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <button
            type="button"
            className="flex bg-slate-300 rounded-lg p-2 justify-center items-center text-sm"
            onClick={switchFields}
          >
            <FaLongArrowAltDown />
          </button>
        </div>
        <div className="w-full bg-slate-300 my-3 rounded-lg p-4">
          <div className="w-full flex justify-between items-center">
            <button
              type="button"
              className="px-5 py-1 rounded-[50px] bg-slate-400 flex items-center"
              onClick={() => setTokenModal2(true)}
            >
              {token2?.symbol ? token2?.symbol : "Select token"}
              <FaChevronDown className="mx-2" />
            </button>
            <div className="w-auto">{formatBalance(token2?.balance, token2?.symbol)}</div>
          </div>
          <div className="">
            {/* <input
              type="text"
              className=" text-right w-full py-0 text-xl bg-transparent outline-none text-slate-600"
              placeholder="0"
              value={field2Value}
              disabled={true}
            /> */}
          </div>
        </div>
        <div className="w-full bg-slate-200 rounded-lg flex flex-col mb-3">
          <div className="w-full text-center my-2">Reserves</div>
          <div className="w-full flex">
            <div className="w-1/2 text-center mb-3">{formatReserve(reserves[0], token1?.symbol)}</div>
            <div className="w-1/2  text-center mb-3">{formatReserve(reserves[1], token2?.symbol)}</div>
          </div>
          <div className="w-full text-center my-2">Your Liquidity Pool Tokens</div>
          <div className="w-full flex">
            <div className="w-full text-center mb-3">{formatReserve(liquidityTokens, "UNI-V2")}</div>
          </div>
        </div>
        <div className="w-full bg-slate-200 rounded-lg flex flex-col mb-3">
          <div className="w-full text-center my-2">Liquidity Pool Tokens in</div>
          <div className="w-full flex">
            <div className="w-full text-center mb-3">{formatBalance(tokensOut[0], "UNI-V2")}</div>
          </div>
          <div className="w-full text-center my-2">Tokens Out</div>
          <div className="w-full flex">
            <div className="w-1/2 text-center mb-3">{formatBalance(tokensOut[1], token1?.symbol)}</div>
            <div className="w-1/2  text-center mb-3">{formatBalance(tokensOut[2], token2.symbol)}</div>
          </div>
        </div>
        <div className="w-full flex gap-2">
          {token1?.address && token1?.allowance < field1Value ? (
            <button
              type="button"
              className="w-1/2 bg-slate-300 p-3 rounded-lg hover:bg-slate-400 my-2"
              onClick={giveTokenAllowance1}
            >
              Approve ({token1.symbol})
            </button>
          ) : (
            ""
          )}
          {token2?.address && token2?.allowance < field2Value ? (
            <button
              type="button"
              className="w-1/2 bg-slate-300 p-3 rounded-lg hover:bg-slate-400 my-2"
              onClick={giveTokenAllowance2}
            >
              Approve ({token2.symbol})
            </button>
          ) : (
            ""
          )}
        </div>
        <RemoveLiquidityButton
          enable={isButtonEnabled}
          networkInfo={networkInfo}
          connectWallet={connectWallet}
          remove={remove}
        />
      </div>
      <TokensModal
        name="Token List 1"
        show={tokenModal1}
        setTokenModal={setTokenModal1}
        tokens={networkInfo.coins}
        onSelect={setTokenOneData}
        selectedToken={token2}
      />
      <TokensModal
        name="Token List 2"
        show={tokenModal2}
        setTokenModal={setTokenModal2}
        tokens={networkInfo.coins}
        onSelect={setTokenTwoData}
        selectedToken={token1}
      />
    </>
  );
};

export default RemoveLiquidity;
