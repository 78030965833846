import React, { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";

const useGetEtherProvider = () => {
  const [provider, setProvider] = useState();
  const { walletProvider } = useWeb3ModalProvider();
  useMemo(() => {
    const setEtherProvider = async () => {
      if (walletProvider) {
        const ethersProvider = await new ethers.providers.Web3Provider(walletProvider);
        setProvider(ethersProvider);
      } else {
        return false;
      }
    };
    setEtherProvider();
  }, [walletProvider]);
  return provider;
};

export default useGetEtherProvider;
