import * as chains from "./chains";

const SEPOLIA = [
  {
    name: "Sepolia testETH",
    abbr: "SepoliaETH",
    address: "",
  },
  {
    name: "Token1 ",
    abbr: "TKN1",
    address: "0x02e757D47aDc8d9D099EC5f7807C1ceeCfdcF443",
  },
];

const BINANCECHAIN = [
  {
    name: "BNB Testnet",
    abbr: "TBNB",
    address: "",
  },
  {
    name: "Token 1",
    abbr: "TKN1",
    address: "0x5b2F5217B0C8097CDa3aCcB9569CA206649F9D52",
  },
  {
    name: "Token 2",
    abbr: "TKN2",
    address: "0xe151F4B915366F13F02325Ec04BeBe663E0212D6",
  },
  // {
  //   name: "Bsw",
  //   abbr: "BSW",
  //   address: "0x462E75355385fDDb19B4F1A7A6Fca66caC8Cc17a",
  // },
];

const COINS = new Map();
COINS.set(chains.ChainId.BINANCE_TEST, BINANCECHAIN);
COINS.set(chains.ChainId.SEPOLIA_TEST, SEPOLIA);
export default COINS;
