export const formatAddress = (text) => {
  if (text) return text.substr(0, 5) + "..." + text.substr(-5, 5);
  else return "";
};

export const formatBalance = (balance, symbol) => {
  if (balance && symbol) return parseFloat(balance).toFixed(4) + " " + symbol;
  else return "0.0";
};

// Turns the coin's reserves into something nice and readable
export const formatReserve = (reserve, symbol) => {
  if (reserve && symbol) return reserve + " " + symbol;
  else return "0.0";
};
