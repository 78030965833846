import React, { useContext, useEffect, useState } from "react";
import Header from "../components/common/Header";
import { FaChevronDown } from "react-icons/fa";
import TokensModal from "../components/common/TokensModal";
import { FaLongArrowAltDown } from "react-icons/fa";
import { appContext } from "../AppContext";
import { formatAddress, formatReserve, formatBalance } from "../functions/commonFunctions";
import { getBalanceAndSymbol, getReserves, getAmountOut, giveAllowance, swapTokens } from "../functions/etherFunctions";
import SwapTokenButton from "../components/SwapTokenButton";
import DeployLiquidity from "./DeployLiquidity";
import RemoveLiquidity from "./RemoveLiquidity";

const Liquidity = () => {
  const [tab, setTab] = useState("add");
  return (
    <>
      <div className="container mx-auto">
        <Header />
        <div className="flex justify-center">
          <nav className="p-1 flex items-center w-[600px] justify-center rounded-md border bg-slate-50 gap-x-1">
            <a
              type="button"
              className={`px-5 py-2 hover:bg-slate-400 hover:text-white rounded-md w-1/2 text-center ${
                tab === "add" ? "bg-slate-400 text-white" : ""
              }`}
              onClick={() => setTab("add")}
            >
              Deploy Liquidity
            </a>
            <a
              type="button"
              className={`px-5 py-2 hover:bg-slate-400 hover:text-white rounded-md w-1/2 text-center ${
                tab === "remove" ? "bg-slate-400 text-white" : ""
              }`}
              onClick={() => setTab("remove")}
            >
              Remove Liquidity
            </a>
          </nav>
        </div>
        <div className="w-[100%] flex justify-center">
          {tab === "add" ? <DeployLiquidity /> : tab === "remove" ? <RemoveLiquidity /> : ""}
        </div>
      </div>
    </>
  );
};

export default Liquidity;
