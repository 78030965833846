import React from "react";
import { RxCross2 } from "react-icons/rx";
import { IoSearchOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";

const TokensModal = ({ name, show, setTokenModal, tokens, onSelect, selectedToken }) => {
  return (
    <div
      className={`w-full min-h-screen fixed left-0 top-0 bg-slate-800/[0.3] justify-center items-center ${
        show ? "flex" : "hidden"
      }`}
    >
      <div className="w-[400px] min-h-72 bg-slate-50 rounded-xl relative p-4">
        <div className="text-xl mb-2">{name}</div>
        <button
          type="button"
          className="p-2 text-2xl absolute top-[5px] right-0"
          onClick={() => setTokenModal(false)}
        >
          <RxCross2 />
        </button>
        <div className="w-full bg-slate-400 rounded-lg flex">
          <button type="button" className="w-8 text-xl text-white px-2">
            <IoSearchOutline />
          </button>
          <input
            type="text"
            className="text-lg bg-transparent text-white py-2 w-full outline-none placeholder-white"
            placeholder="search"
          />
        </div>
        <div className="text-md text-slate-600 my-2">Common Tokens</div>
        <div className="flex gap-2">
          <a
            type="button"
            className="w-1/4 rounded-md text-white bg-slate-400 border border-slate-600 py-2 font-bold flex justify-center cursor-pointer"
          >
            <img src="assets/images/token-icons/default-icon.png" className="w-6 h-6 mr-1" />
            USDT
          </a>
          <a
            type="button"
            className="w-1/4 rounded-md text-white bg-slate-400 border border-slate-600 py-2 font-bold flex justify-center cursor-pointer"
          >
            <img src="assets/images/token-icons/default-icon.png" className="w-6 h-6 mr-1" />
            USDT
          </a>
          <a
            type="button"
            className="w-1/4 rounded-md text-white bg-slate-400 border border-slate-600 py-2 font-bold flex justify-center cursor-pointer"
          >
            <img src="assets/images/token-icons/default-icon.png" className="w-6 h-6 mr-1" />
            USDT
          </a>
        </div>
        <div className="w-full max-h-[300px] my-3 overflow-auto">
          {tokens && tokens?.length
            ? tokens.map((item, index) => {
                return (
                  <button
                    type="button"
                    className="w-full hover:bg-slate-200 rounded-md flex cursor-pointer px-4 py-2 justify-between items-center disabled:bg-slate-200 disabled:opacity-50 disabled:cursor-auto my-1"
                    onClick={() => {
                      onSelect(item);
                      setTokenModal(false);
                    }}
                    key={index}
                  >
                    <div className="flex">
                      <img src="assets/images/token-icons/default-icon.png" className="w-6 h-6 mr-1" />
                      <span>{item.abbr}</span>
                    </div>
                    <span>
                      <FaArrowRightLong />
                    </span>
                  </button>
                );
              })
            : "Tokens not found"}
        </div>
      </div>
    </div>
  );
};

export default TokensModal;
