import React, { createContext, useEffect, useState } from "react";
import { getWeth, getRouter, getFactory } from "./functions/etherFunctions";
import {
  createWeb3Modal,
  defaultConfig,
  // useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useWeb3Modal,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import COINS from "./constants/coins";
import * as chains from "./constants/chains";
import useGetEtherProvider from "./hooks/useGetEtherProvider";

const projectId = "2cef6c82df232069b70b28dce25b4d8c";

const mainnet = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com",
  rpcUrl: "https://data-seed-prebsc-1-s3.bnbchain.org:8545",
};

const sepolia = {
  chainId: 11155111,
  name: "Sepolia test network",
  currency: "SepoliaETH",
  explorerUrl: "https://sepolia.etherscan.io/",
  rpcUrl: "https://api.zan.top/node/v1/eth/sepolia/public",
};

const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
});
createWeb3Modal({
  ethersConfig,
  chains: [mainnet, sepolia],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export const appContext = createContext();

const AppContext = ({ children }) => {
  const { address, chainId } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  // const { disconnect } = useDisconnect();
  const [slippagePer, setSlippagePer] = useState(0.5);
  const [minimumAmountOut, setMinimumAmountOut] = useState(0);
  const [priceImpact, setPriceImpact] = useState(0);
  const [tradingFee, setTradingFee] = useState(0);
  const [networkInfo, setNetworkInfo] = useState({});
  const provider = useGetEtherProvider();

  const connectWallet = () => {
    try {
      open();
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const getNetworkDetails = async () => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      if (ethersProvider && signer) {
        networkInfo.provider = ethersProvider;
        networkInfo.signer = signer;
        let coins = COINS.get(chainId);
        let router = await getRouter(chains.routerAddress.get(chainId), signer);
        let wethAddress = await router.WETH();
        coins[0].address = wethAddress;
        let weth = await getWeth(wethAddress, signer);
        let factory_address = await router.factory();
        let factory = await getFactory(factory_address, signer);
        networkInfo.isConnected = true;

        setNetworkInfo((prev) => ({
          ...prev,
          isConnected: true,
          provider: ethersProvider,
          signer: signer,
          address: address,
          chainid: chainId,
          coins: coins,
          router,
          factory,
          weth,
        }));
      } else {
        setNetworkInfo((prev) => ({ ...prev, isConnected: false }));
      }
    } catch (err) {
      console.log("error in getNetworkDetails : ", err);
      setNetworkInfo((prev) => ({ ...prev, isConnected: false }));
    }
  };

  useEffect(() => {
    if (address && chainId && walletProvider) {
      getNetworkDetails();
    } else {
      setNetworkInfo((prev) => ({ ...prev, isConnected: false }));
    }
  }, [address, chainId, walletProvider]);

  return (
    <appContext.Provider
      value={{
        connectWallet,
        networkInfo,
        slippagePer,
        setSlippagePer,
        minimumAmountOut,
        setMinimumAmountOut,
        priceImpact,
        setPriceImpact,
        tradingFee,
        setTradingFee,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppContext;
