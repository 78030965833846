export const networks = [97];

export const ChainId = {
  BINANCE_TEST: 97,
  SEPOLIA_TEST: 11155111,
};

export const routerAddress = new Map();
routerAddress.set(ChainId.BINANCE_TEST, "0xD99D1c33F9fC3444f8101754aBC46c52416550D1");
routerAddress.set(ChainId.SEPOLIA_TEST, "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008");
