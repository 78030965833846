import { BrowserProvider, Contract, formatUnits, ethers, utils } from "ethers";
import ERC20 from "../abis/ERC20.json";
import FACTORY from "../abis/IUniswapV2Factory.json";
import ROUTER from "../abis/UniswapV2Router02.json";
import PAIR from "../abis/IUniswapV2Pair.json";

export function getWeth(address, signer) {
  return new Contract(address, ERC20.abi, signer);
}

export function getRouter(address, signer) {
  return new Contract(address, ROUTER.abi, signer);
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // console.log("No tokenDecimals function for this token, set to 0");
      return 0;
    });
  return decimals;
}

export async function getBalanceAndSymbol(
  accountAddress,
  address,
  provider,
  signer,
  weth_address,
  coins,
  routerContract
) {
  try {
    if (address === weth_address) {
      const balanceRaw = await provider.getBalance(accountAddress);

      return {
        balance: ethers.utils.formatEther(balanceRaw),
        symbol: coins[0].abbr,
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const tokenDecimals = await getDecimals(token);
      const balanceRaw = await token.balanceOf(accountAddress);
      const allowance = await token.allowance(accountAddress, routerContract.address);
      const symbol = await token.symbol();
      // console.log("balanceRaw * 10 ** -tokenDecimals => ", address + " ", allowance * 10 ** -tokenDecimals);
      return {
        balance: balanceRaw * 10 ** -tokenDecimals,
        symbol: symbol,
        allowance: parseFloat(allowance),
      };
    }
  } catch (error) {
    // console.log("The getBalanceAndSymbol function had an error!");
    console.log(error);
    return false;
  }
}

export async function fetchReserves(address1, address2, pair, signer) {
  try {
    // Get decimals for each coin
    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin2 = new Contract(address2, ERC20.abi, signer);

    const coin1Decimals = await getDecimals(coin1);
    const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();
    console.log("reserves (wei) : [", reservesRaw[0].toString(), ", ", reservesRaw[1].toString(), "]");
    // console.log("_________________________________________________________");

    // Put the results in the right order
    const results = [
      (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
    ];

    // Scale each to the right decimal place
    console.log(
      "reserves (ether) : [",
      results[0] * 10 ** -coin1Decimals,
      ", ",
      results[1] * 10 ** -coin2Decimals,
      "]"
    );
    return [results[0] * 10 ** -coin1Decimals, results[1] * 10 ** -coin2Decimals];
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0];
  }
}

export async function getReserves(address1, address2, factory, signer, accountAddress) {
  try {
    const pairAddress = await factory.getPair(address1, address2);
    console.log("account address : ", accountAddress);
    console.log("pair address : ", pairAddress);
    const pair = new Contract(pairAddress, PAIR.abi, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const liquidityTokens_BN = await pair.balanceOf(accountAddress);

      console.log("liquidity tokens _BN (wei) : ", liquidityTokens_BN.toString());
      const liquidityTokens = Number(ethers.utils.formatEther(liquidityTokens_BN));
      console.log("liquidity tokens _BN (ether) : ", liquidityTokens);
      console.log("pair contract : ", pair);

      return [reservesRaw[0].toFixed(4), reservesRaw[1].toFixed(4), liquidityTokens];
    } else {
      // console.log("no reserves yet");
      return [0, 0, 0];
    }
  } catch (err) {
    // console.log("error!");
    console.log(err);
    return [0, 0, 0];
  }
}

export async function getAmountOut(address1, address2, amountIn, routerContract, signer, slippage) {
  try {
    // console.log(" slippage", slippage);
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    const values_out = await routerContract.getAmountsOut(
      ethers.utils.parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );
    console.log("router contract : ", routerContract);
    console.log("reserves (wei) : [", values_out[0].toString(), ", ", values_out[1].toString(), "]");
    const amountOutMinWei = values_out[1];
    const slippageAdjustedAmountOutMin = amountOutMinWei
      .sub(amountOutMinWei.mul(Number(slippage * 10000)).div(100 * 10000))
      .toString();
    // const slippageAdjustedAmountOutMax = ethers.utils.parseEther(
    //   amountOutMinWei.mul(Number(slippage * 10000)).div(100 * 10000)
    // );
    // console.log("slippageAdjustedAmountOutMin => maxxxxx =>", slippageAdjustedAmountOutMax);
    // console.log(
    //   "liquidity : ",
    //   Number(ethers.utils.formatEther(values_out[0])),
    //   Number(ethers.utils.formatEther(values_out[1]))
    // );
    const amount_out = values_out[1] * 10 ** -token2Decimals;
    return {
      amountOut: Number(amount_out),
      minamountOut: ethers.utils.formatEther(slippageAdjustedAmountOutMin),
    };
  } catch (err) {
    console.log("error : ", err);
    return false;
  }
}

function delay(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
export const checkHashStatusIsComplete = async (hash, provider, noOfTime = 25) => {
  for (let i = 0; i < noOfTime && i < noOfTime; i++) {
    try {
      const trx = await provider.getTransactionReceipt(hash);
      if (trx) {
        return { status: true };
      }
    } catch (err) {
      if (err.message === "Transaction not found") {
      } else {
        return { status: false, error: err.message };
      }
    }
    await delay(2000);
  }
};

export async function giveAllowance(address, routerContract, signer, provider) {
  try {
    const token = new Contract(address, ERC20.abi, signer);
    const supply = await token.totalSupply();
    const tx = await token.approve(routerContract.address, supply);
    await tx.wait();
    // const result = await checkHashStatusIsComplete(reciept?.hash, provider);
    return true;
  } catch {
    return false;
  }
}

export async function swapTokens(
  address1,
  address2,
  amount,
  routerContract,
  accountAddress,
  signer,
  provider,
  slippage
) {
  try {
    const tokens = [address1, address2];
    const time = Math.floor(Date.now() / 1000) + 200000;
    const deadline = ethers.BigNumber.from(time);

    const token1 = new Contract(address1, ERC20.abi, signer);
    const tokenDecimals = await getDecimals(token1);

    const amountIn = await ethers.utils.parseUnits(amount, tokenDecimals);
    const amountOut = await routerContract.getAmountsOut(amountIn, tokens);
    const amountOutMinWei = amountOut[1];
    const slippageAdjustedAmountOutMin = amountOutMinWei
      .sub(amountOutMinWei.mul(Number(slippage * 10000)).div(100 * 10000))
      .toString();

    const wethAddress = await routerContract.WETH();
    if (address1 === wethAddress) {
      // Eth -> Token
      const tx = await routerContract.swapExactETHForTokens(
        slippageAdjustedAmountOutMin,
        tokens,
        accountAddress,
        deadline,
        {
          value: amountIn,
        }
      );
      await tx.wait();
    } else if (address2 === wethAddress) {
      // Token -> Eth
      const tx = await routerContract.swapExactTokensForETH(
        amountIn,
        slippageAdjustedAmountOutMin,
        tokens,
        accountAddress,
        deadline
      );
      await tx.wait();
    } else {
      const tx = await routerContract.swapExactTokensForTokens(
        amountIn,
        slippageAdjustedAmountOutMin,
        tokens,
        accountAddress,
        deadline
      );
      await tx.wait();
      // const result = await checkHashStatusIsComplete(reciept?.hash, provider);
    }
  } catch (err) {
    console.log("error in swapTokens : ", err);
  }
}
