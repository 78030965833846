import React, { useEffect, useState } from "react";

const RemoveLiquidityButton = ({ enable, networkInfo, connectWallet, remove }) => {
  const disabledBtn = enable();
  return (
    <div className="w-full flex gap-2">
      {networkInfo.isConnected ? (
        <button
          type="button"
          className="w-full bg-slate-300 p-3 rounded-lg hover:bg-slate-400 my-2 disabled:opacity-50 disabled:hover:bg-slate-300"
          disabled={disabledBtn ? false : true}
          onClick={remove}
        >
          Remove
        </button>
      ) : (
        <button
          type="button"
          className="w-full bg-slate-300 p-3 rounded-lg hover:bg-slate-400 my-2"
          onClick={connectWallet}
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default RemoveLiquidityButton;
