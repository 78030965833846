import "./App.css";
import { Route, Routes } from "react-router-dom";
import Liquidity from "./pages/Liquidity";
import Swap from "./pages/Swap";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Swap />} />
      <Route path="/liquidity" element={<Liquidity />} />
    </Routes>
  );
}

export default App;
