import React, { useContext } from "react";
import { appContext } from "../../AppContext";

const SlipageBox = () => {
  const { slippagePer, setSlippagePer } = useContext(appContext);
  return (
    <div className="w-full font-bold text-slate-600">
      <div className="w-full text-sm text-slate-600">Slippage ({slippagePer}%)</div>
      <nav className="p-1 flex items-center w-full mb-4 justify-center border bg-slate-50 gap-x-1 rounded-[60px] mt-2">
        <button
          type="button"
          className={`px-5 py-2 hover:bg-slate-400 hover:text-white w-1/4 text-center rounded-[60px] cursor-pointer flex items-center justify-center border ${
            slippagePer === 0.1 ? "bg-slate-400 text-white" : ""
          }`}
          onClick={() => setSlippagePer(0.1)}
        >
          0.1%
        </button>
        <button
          type="button"
          className={`px-5 py-2 hover:bg-slate-400 hover:text-white w-1/4 text-center rounded-[60px] cursor-pointer flex items-center justify-center border ${
            slippagePer === 0.5 ? "bg-slate-400 text-white" : ""
          }`}
          onClick={() => setSlippagePer(0.5)}
        >
          0.5%
        </button>
        <button
          type="button"
          className={`px-5 py-2 hover:bg-slate-400 hover:text-white w-1/4 text-center rounded-[60px] cursor-pointer flex items-center justify-center border ${
            slippagePer === 1 ? "bg-slate-400 text-white" : ""
          }`}
          onClick={() => setSlippagePer(1.0)}
        >
          1.0%
        </button>
        <input
          type="text"
          placeholder={slippagePer + "%"}
          value={slippagePer}
          className={`px-5 py-2 w-1/4 text-center rounded-[60px] cursor-pointer flex items-center justify-center border`}
          onChange={(e) => {
            if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) setSlippagePer(Number(e.target.value));
          }}
          onBlur={() => {
            if (slippagePer === "" || slippagePer === 0 || slippagePer === "0") setSlippagePer(parseFloat(0.5));
          }}
        />
        %
      </nav>
    </div>
  );
};

export default SlipageBox;
