import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex justify-center">
      <nav className="p-1 flex items-center w-[500px] my-4 justify-center rounded-md border bg-slate-50 gap-x-1">
        <Link
          to="/"
          className={`px-5 py-2 hover:bg-slate-400 hover:text-white rounded-md w-1/2 text-center ${
            pathname === "/" ? "bg-slate-400 text-white" : ""
          }`}
        >
          Swap
        </Link>
        <Link
          to="/liquidity"
          className={`px-5 py-2 hover:bg-slate-400 hover:text-white rounded-md w-1/2 text-center ${
            pathname === "/liquidity" ? "bg-slate-400 text-white" : ""
          }`}
        >
          Liquidity
        </Link>
      </nav>
    </div>
  );
};

export default Header;
